import { combineReducers } from 'redux';
import filterReducer from './filter/reducer';
import plantReducer from './plant/reducer';

export default function createReducer() {
    const rootReducer = combineReducers({
        filter: filterReducer,
        plant: plantReducer
    });

    return rootReducer;
}
