import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Authenticator } from "aws-amplify-react";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import AuthWrapper from './AuthWrapper';
import './App.scss';


Amplify.configure(awsExports);

const App = () => (
    <>
        <CssBaseline />
        <div className="App">
            <Authenticator hideDefault={true} amplifyConfig={awsExports}>
                <AuthWrapper />
            </Authenticator>
        </div>
    </>
);

export default App;
