import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { FormContainer } from './FormContainer';

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(12),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '300px',
            padding: theme.spacing(3),
        },
    })
);

export const FormSection = ({ children }) => {
    const classes = useStyles();

    return (
        <FormContainer>
            <Paper className={classes.paper}>{children}</Paper>
        </FormContainer>
    );
};