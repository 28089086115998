import React from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import Button from '@material-ui/core/Button';
import Manager from './Manager';
import '@aws-amplify/ui/dist/style.css';
import Container from '@material-ui/core/Container';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Auth } from 'aws-amplify';

const ManageApp = ({ authState, onStateChange }) => {

    async function signOut() {
        try {
            await Auth.signOut();
            onStateChange('signedOut', null);
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <div className="Manager">
            <Container maxWidth="xl">
                <header className="Manager__Header">
                    <div className="Manager__Logo">
                        <img src="/bbc.svg" alt="BBC" className="Manager__Logo" />
                        <h1>Plant Britain Map User Submissions</h1>
                    </div>
                    {authState.toLowerCase() === AuthState.SignedIn && <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<ExitToAppIcon />}
                        onClick={signOut}
                    >
                        Logout
                    </Button>}
                </header>

                <Manager />
            </Container>
        </div>
    );
};

export default ManageApp;
