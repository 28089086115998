import React, { useState } from "react";
import { FormSection } from './UI/FormSection';
import { SectionHeader } from './UI/SectionHeader';
import { SectionBody } from './UI/SectionBody';
import { SectionFooter } from './UI/SectionFooter';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from '@material-ui/lab';
const validAuthStates = ["forgotPassword"];

const CustomForgot = ({ authState, onStateChange, authData }) => {
    const [error, setError] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [username, setUsername] = useState(null);
    const { register, handleSubmit, formState, reset } = useForm({
        mode: "onChange"
    });
    const { isDirty, isValid } = formState;

    const resendCode = async ({ username }) => {
        try {
            const data = await Auth.forgotPassword(username);
            reset();
            setUsername(username);
            setDelivery(data.CodeDeliveryDetails);
        } catch (err) {
            if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError("Username not found");
            } else {
                setError("An error has occurred.");
                console.error(err);
            }
        }

    };

    const submitCode = async ({ code, password }) => {
        const getUsername = username || authData.username;
        try {
            await Auth.forgotPasswordSubmit(getUsername, code, password);
            onStateChange("signIn", {});
            setDelivery(null);
        } catch (err) {
            if (err.code === "CodeMismatchException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError("Code invalid");
            } else {
                setError("An error has occurred.");
                console.error(err);
            }
        }

    };

    const isSubmitDisabled = () => {
        let submitDisabled = true;
        if (isDirty && isValid) {
            submitDisabled = false;
        } else submitDisabled = true;
        return submitDisabled;
    };

    if (!validAuthStates.includes(authState)) {
        return null;
    }

    const FormBack = () => (
        <Grid container style={{ marginTop: '15px' }}>
            <Grid item xs>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => onStateChange("signIn", {})}
                    type="button"
                >
                    Back to Sign In</Link>

            </Grid>
        </Grid>
    );

    const resendForm = () => (
        <form onSubmit={handleSubmit(resendCode)} style={{ width: '100%' }}>
            <SectionBody>
                {error && <Alert severity="error" style={{ marginBottom: '20px' }}>
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>}
                <TextField
                    id="username"
                    variant="outlined"
                    label="Username"
                    name="username"
                    type="text"
                    fullWidth
                    autoFocus
                    required
                    inputRef={register({ required: true })}
                    style={{ marginBottom: '20px' }}
                />
            </SectionBody>
            <SectionFooter>
                <Button
                    type="submit"
                    disabled={isSubmitDisabled()}
                    fullWidth
                    variant="contained"
                    color="primary"
                >Send code</Button>
                <FormBack />
            </SectionFooter>
        </form>
    );

    const submitForm = () => (
        <form onSubmit={handleSubmit(submitCode)} style={{ width: '100%' }}>
            <SectionBody>
                {error && <Alert severity="error" style={{ marginBottom: '20px' }}>
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>}
                <TextField
                    id="code"
                    variant="outlined"
                    label="Code"
                    name="code"
                    type="text"
                    fullWidth
                    autoFocus
                    required
                    inputRef={register({ required: true })}
                    style={{ marginBottom: '20px' }}
                    autoComplete="off"
                />
                <TextField
                    id="password"
                    variant="outlined"
                    label="Password"
                    name="password"
                    type="password"
                    fullWidth
                    required
                    inputRef={register({ required: true })}
                />
            </SectionBody>
            <SectionFooter>
                <Button
                    type="submit"
                    // disabled={isSubmitDisabled()}
                    fullWidth
                    variant="contained"
                    color="primary"
                >Submit</Button>
                <FormBack />
            </SectionFooter>
        </form>
    );

    return (
        <FormSection>
            <SectionHeader>
                Reset your password
                </SectionHeader>
            {delivery || authData.username
                ? submitForm()
                : resendForm()}
        </FormSection>
    );

};

export default CustomForgot;