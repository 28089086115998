import { SET_FILTER, SET_SORT } from "./constants";

export const initialState = {
    areaCode: null,
    areaDescriptiveName: { eq: null },
    createdAt: { between: null },
    flowerTotal: { ge: null },
    fruitVegTotal: { ge: null },
    plantTotal: { ge: null },
    treeTotal: { ge: null },
    gardenTotal: { ge: null },
    riversAndCoastalTotal: { ge: null },
    combinedFilters: null,
    sortDirection: {
        field: 'date',
        sort: 'desc',
    }
};

const isEmpty = obj => Object.keys(obj).length === 0;

const formatFilter = (toFilter, filter, filters) => {
    const filtersToSet = { ...filters };
    if (toFilter && filter !== '' && filter !== 'flowerTotal' && filter !== 'fruitVegTotal' && filter !== 'plantTotal' && filter !== 'treeTotal' && filter !== 'gardenTotal' && filter !== 'riversAndCoastalTotal') {
        if (toFilter === 'createdAt') {
            filtersToSet[toFilter].between = filter;
        } else if (toFilter === 'areaCode') {
            filtersToSet[toFilter] = filter;
        } else {
            filtersToSet[toFilter].eq = filter;
        }

    } else if (filter !== '') {
        filtersToSet['fruitVegTotal'].ge = null;
        filtersToSet['flowerTotal'].ge = null;
        filtersToSet['plantTotal'].ge = null;
        filtersToSet['treeTotal'].ge = null;
        filtersToSet['gardenTotal'].ge = null;
        filtersToSet['riversAndCoastalTotal'].ge = null;

        if (filter !== null) {
            filtersToSet[filter].ge = 1;
        }
    }

    const filterArr = [];

    for (const [key, value] of Object.entries(filters)) {
        if (value?.hasOwnProperty('eq')) {
            if (value.eq !== '' && value.eq !== null) {
                filterArr.push({ key, value });
            }
        } else if (value?.hasOwnProperty('between')) {
            if (value.between !== 'undefined' && value.between !== undefined && value.between !== null) {
                filterArr.push({ key, value });
            }
        } else if (value?.hasOwnProperty('ge')) {
            if (value.ge !== '' && value.ge !== 'undefined' && value.ge !== undefined && value.ge !== null) {
                filterArr.push({ key, value });
            }
        }
    }

    const combinedFilters = {};
    for (let i = 0, n = filterArr.length; i < n; i += 1) {
        combinedFilters[filterArr[i].key] = filterArr[i].value;
    }

    if (isEmpty(combinedFilters)) {
        filtersToSet['combinedFilters'] = null;
    } else {
        filtersToSet['combinedFilters'] = {
            and: [
                combinedFilters,
            ],
        };
    }

    return filtersToSet;
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER:
            const filter = formatFilter(action.toFilter, action.filter, state);
            return {
                ...state,
                ...filter,
            };
        case SET_SORT:
            return {
                ...state,
                sortDirection: action.payload,
            };
        default:
            return state;
    }
};

export default filterReducer;
