import React, { useState } from "react";
import { FormSection } from './UI/FormSection';
import { SectionHeader } from './UI/SectionHeader';
import { SectionBody } from './UI/SectionBody';
import { SectionFooter } from './UI/SectionFooter';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from '@material-ui/lab';
import { isEmpty, ConsoleLogger as Logger } from '@aws-amplify/core';
const validAuthStates = ["signIn", "signedOut", "signedUp"];
const logger = new Logger('SignIn');
const CustomSignIn = ({ authState, onStateChange }) => {
    const [error, setError] = useState(null);
    const { register, handleSubmit, formState } = useForm({
        mode: "onChange"
    });
    const { isDirty, isValid } = formState;

    const checkContact = async (user) => {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        try {
            const data = await Auth.verifiedContact(user);
            if (!isEmpty(data.verified)) {
                onStateChange('signedIn', user);
            } else {
                user = Object.assign(user, data);
                onStateChange('verifyContact', user);
            }
        } catch (error) {

        }
    };


    const signIn = async ({ username, password }) => {
        try {
            const user = await Auth.signIn(username, password);
            logger.debug(user);
            if ((user.preferredMFA && user.preferredMFA === "NOMFA")) {
                onStateChange('TOTPSetup', user);
            } else if (
                user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA'
            ) {
                logger.debug('confirm user with ' + user.challengeName);
                onStateChange('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logger.debug('require new password', user.challengeParam);
                onStateChange('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                onStateChange('TOTPSetup', user);
            } else if (
                user.challengeName === 'CUSTOM_CHALLENGE' &&
                user.challengeParam &&
                user.challengeParam.trigger === 'true'
            ) {
                logger.debug('custom challenge', user.challengeParam);
                onStateChange('customConfirmSignIn', user);
            } else {
                checkContact(user);
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                await Auth.resendSignUp(username);
                onStateChange("confirmSignUp", {});
            } else if (err.code === "NotAuthorizedException") {
                // The error happens when the incorrect password is provided
                setError("Login failed.");
            } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError("Login failed.");
            } else {
                setError("An error has occurred.");
                console.error(err);
            }
        }

    };

    const isSubmitDisabled = () => {
        let submitDisabled = true;
        if (isDirty && isValid) {
            submitDisabled = false;
        } else submitDisabled = true;
        return submitDisabled;
    };

    if (!validAuthStates.includes(authState)) {
        return null;
    }

    return (
        <FormSection>
            <SectionHeader>
                Sign in to your account
                </SectionHeader>
            <form onSubmit={handleSubmit(signIn)} style={{ width: '100%' }}>
                <SectionBody>
                    {error && <Alert severity="error" style={{ marginBottom: '20px' }}>
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}

                    <TextField
                        id="username"
                        variant="outlined"
                        label="Username"
                        name="username"
                        type="text"
                        fullWidth
                        autoFocus
                        required
                        inputRef={register({ required: true })}
                        style={{ marginBottom: '20px' }}
                    />
                    <TextField
                        id="password"
                        variant="outlined"
                        label="Password"
                        name="password"
                        type="password"
                        fullWidth
                        required
                        inputRef={register({ required: true })}
                    />
                </SectionBody>
                <SectionFooter>
                    <Button
                        type="submit"
                        disabled={isSubmitDisabled()}
                        fullWidth
                        variant="contained"
                        color="primary"
                    >Sign In</Button>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs>
                            Forgot your password?{" "}
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => onStateChange("forgotPassword", {})}
                                type="button"
                            >
                                Reset Password</Link>

                        </Grid>
                    </Grid>
                </SectionFooter>
            </form>
        </FormSection>
    );

};

export default CustomSignIn;
