/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlant = /* GraphQL */ `
  query GetPlant($id: ID!) {
    getPlant(id: $id) {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const listPlants = /* GraphQL */ `
  query ListPlants(
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAggregatedPlant = /* GraphQL */ `
  query GetAggregatedPlant($areaCode: String!) {
    getAggregatedPlant(areaCode: $areaCode) {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
export const listAggregatedPlants = /* GraphQL */ `
  query ListAggregatedPlants(
    $areaCode: String
    $filter: ModelaggregatedPlantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAggregatedPlants(
      areaCode: $areaCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        areaCode
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByAreaCodeAndCreatedAt = /* GraphQL */ `
  query ItemsByAreaCodeAndCreatedAt(
    $areaCode: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByAreaCodeAndCreatedAt(
      areaCode: $areaCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createdAt = /* GraphQL */ `
  query CreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    createdAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByPlantTotal = /* GraphQL */ `
  query ItemsByPlantTotal(
    $type: String
    $plantTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByPlantTotal(
      type: $type
      plantTotal: $plantTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByGardenTotal = /* GraphQL */ `
  query ItemsByGardenTotal(
    $type: String
    $gardenTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByGardenTotal(
      type: $type
      gardenTotal: $gardenTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByTreeTotal = /* GraphQL */ `
  query ItemsByTreeTotal(
    $type: String
    $treeTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByTreeTotal(
      type: $type
      treeTotal: $treeTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByFlowerTotal = /* GraphQL */ `
  query ItemsByFlowerTotal(
    $type: String
    $flowerTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByFlowerTotal(
      type: $type
      flowerTotal: $flowerTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByFruitVegTotal = /* GraphQL */ `
  query ItemsByFruitVegTotal(
    $type: String
    $fruitVegTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByFruitVegTotal(
      type: $type
      fruitVegTotal: $fruitVegTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByAreaCodeAndPlantTotal = /* GraphQL */ `
  query ItemsByAreaCodeAndPlantTotal(
    $areaCode: String
    $plantTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByAreaCodeAndPlantTotal(
      areaCode: $areaCode
      plantTotal: $plantTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByAreaCodeAndTreeTotal = /* GraphQL */ `
  query ItemsByAreaCodeAndTreeTotal(
    $areaCode: String
    $treeTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByAreaCodeAndTreeTotal(
      areaCode: $areaCode
      treeTotal: $treeTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByAreaCodeAndFlowerTotal = /* GraphQL */ `
  query ItemsByAreaCodeAndFlowerTotal(
    $areaCode: String
    $flowerTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByAreaCodeAndFlowerTotal(
      areaCode: $areaCode
      flowerTotal: $flowerTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByAreaCodeAndFruitVegTotal = /* GraphQL */ `
  query ItemsByAreaCodeAndFruitVegTotal(
    $areaCode: String
    $fruitVegTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByAreaCodeAndFruitVegTotal(
      areaCode: $areaCode
      fruitVegTotal: $fruitVegTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaCode
        areaDescriptiveName
        plantTotal
        gardenTotal
        riversAndCoastalTotal
        treeTotal
        flowerTotal
        fruitVegTotal
        type
        adminUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
