import React, { useEffect } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ManageApp from './pages/ManageApp.jsx';

const InternalApp = ({ authState, authData, onStateChange }) => {

    useEffect(() => {
        if ((authState.toLowerCase() === AuthState.SignedIn && (authData.preferredMFA === "NOMFA"))) {
            onStateChange('TOTPSetup', authData);
        }
    }, [authData, authState, onStateChange]);

    if ((authState.toLowerCase() !== AuthState.SignedIn) || (authState.toLowerCase() === AuthState.SignedIn && (authData.preferredMFA === "NOMFA"))) {
        return null;
    }

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path='/'>
                        <ManageApp authState={authState} onStateChange={onStateChange} />
                    </Route>
                </Switch>
            </Router>
        </>);
};

export default InternalApp;