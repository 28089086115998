import React, { useEffect, useState } from 'react';
import { formatISO, format } from 'date-fns';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { DateRangePicker } from "materialui-daterange-picker";
import { setTableFilter } from '../store/filter/actions';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormControl from '@material-ui/core/FormControl';

const FilterDate = ({
    toFilter,
    id,
}) => {
    const dispatch = useDispatch();
    const initialDateFilter = ['1991-12-08T00:00:00.000Z', '2120-01-01T23:59:59.000Z'];
    const [filter, setFilter] = useState('NALoad');
    const [showDate, setShowDate] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = date => {
        const startDate = formatISO(date.startDate, { representation: 'date' });
        const endDate = formatISO(date.endDate, { representation: 'date' });

        setFilter([`${startDate}T00:00:00.000Z`, `${endDate}T23:59:59.000Z`]);
        setSelectedDate(`${format(date.startDate, 'd/M/yy')} - ${format(date.endDate, 'd/M/yy')}`);
        setShowDate(false);
    };

    const clearDateFilter = () => {
        console.log('clearDateFilter');
        setSelectedDate('');
        setFilter(initialDateFilter);
        setShowDate(false);
    };

    useEffect(() => {
        if (filter !== 'NALoad') {
            dispatch(setTableFilter(toFilter, filter));
        }
        // eslint-disable-next-line
    }, [filter]);

    const toggle = () => setShowDate(!showDate);

    return (
        <div className={`Manager__Filter ${showDate ? 'show' : ''}`}>
            <FormControl onClick={() => setShowDate(true)} >
                <TextField label="Select date" value={selectedDate} InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <DateRangeIcon />
                        </InputAdornment>
                    ),
                }}></TextField>
            </FormControl>
            {selectedDate && selectedDate !== '' && (<Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={clearDateFilter}
                className={`Manager__Filter-clear ${showDate ? 'show' : ''}`}
            >
                Clear date
            </Button>)}

            <div className="Manager__DateRange">
                <DateRangePicker open={showDate} toggle={toggle} onChange={handleDateChange} />
            </div>
        </div>
    );
};

export default FilterDate;
