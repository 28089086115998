import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDispatch } from 'react-redux';
import { setTableFilter } from '../store/filter/actions';

const FilterAutocomplete = ({
    filterItem,
    toFilter,
    label,
    id,
}) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const handleChange = (event, newValue) => {
        dispatch(setTableFilter(toFilter, newValue?.graphQLName || null));
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.graphQLName,
    });

    return (
        <div className="Manager__Filter">
            <FormControl>
                <Autocomplete
                    id={id}
                    options={filterItem}
                    getOptionLabel={(option) => option?.humanReadable}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    onChange={handleChange}
                    filterOptions={filterOptions}
                />
            </FormControl>

        </div>
    );
};

export default FilterAutocomplete;
