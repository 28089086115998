import React from 'react';
import FilterDate from './FilterDate';
import postCodes from '../data/postcodeArea';
import FilterAutocomplete from './FilterAutocomplete';

const areaCodesList = postCodes.map(i => {
    return {
        graphQLName: i.Postcode,
        humanReadable: `${i.Postcode} (${i.Name})`
    };
});

const plantTypeList = [
    {
        graphQLName: 'flowerTotal',
        humanReadable: 'Flowers'
    },
    {
        graphQLName: 'fruitVegTotal',
        humanReadable: 'Fruit / Veg'
    },
    {
        graphQLName: 'plantTotal',
        humanReadable: 'Plants'
    },
    {
        graphQLName: 'treeTotal',
        humanReadable: 'Trees'
    },
];

const FilterController = () => {
    return (
        <div className="Manager__Filters">
            <FilterAutocomplete
                filterItem={areaCodesList}
                label="Select Postcode Area"
                id="select-postcode-area"
                toFilter="areaCode"
            />
            {/* 
            <Filter
                filterItem={plantTypeList}
                label="Show Only"
                id="select-plant-type"
            /> */}

            <FilterDate
                id="date-picker-dialog"
                toFilter="createdAt"
            />
        </div>
    );
};

export default FilterController;
