import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';

import {
    createdAt,
    itemsByTreeTotal,
    itemsByAreaCodeAndCreatedAt,
    itemsByAreaCodeAndTreeTotal,
    itemsByAreaCodeAndFruitVegTotal,
    itemsByFruitVegTotal,
    itemsByAreaCodeAndPlantTotal,
    itemsByPlantTotal,
    itemsByAreaCodeAndFlowerTotal,
    itemsByFlowerTotal
} from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { createPlant, deletePlant } from '../graphql/mutations';

import Table from '../components/Table';
import AddSubmissionModal from '../components/AddSubmissionModal';
import FilterController from '../components/FilterController';
import TablePagination from '@material-ui/core/TablePagination';
import { useSelector, useDispatch } from 'react-redux';
import { selectCombinedFilter, selectAreaCode, selectSortDirection } from '../store/filter/selectors';
import { setPlants } from '../store/plant/actions';
import { selectPlants } from '../store/plant/selectors';
import Totals from '../components/Totals';

const Manager = () => {
    const dispatch = useDispatch();
    const combinedFilters = useSelector(selectCombinedFilter);
    const areaCode = useSelector(selectAreaCode);
    const sortDirection = useSelector(selectSortDirection);
    const plants = useSelector(selectPlants);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddSubmissionModal, setShowAddSubmissionModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    // pagination
    const [currentToken, setCurrentToken] = useState(undefined);
    const [previousTokens, setPreviousTokens] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const [rowCount, setRowCount] = useState(-1);

    const fetchPlantPostData = useCallback(async (token) => {
        try {
            setIsLoading(true);
            let variables = {
                type: 'plant',
                nextToken: token,
                limit: rowsPerPage,
                filter: combinedFilters,
                sortDirection: sortDirection.sort.toUpperCase()
            };

            if (areaCode) {
                variables.areaCode = areaCode;
            }
            let result;
            let items = [];
            let currentToken;

            switch (sortDirection.field) {
                case 'treeTotal':
                    if (areaCode) {
                        result = await API.graphql(graphqlOperation(itemsByAreaCodeAndTreeTotal, variables));
                        items = result.data.itemsByAreaCodeAndTreeTotal.items;
                        currentToken = result.data.itemsByAreaCodeAndTreeTotal.nextToken;
                    } else {
                        result = await API.graphql(graphqlOperation(itemsByTreeTotal, variables));
                        items = result.data.itemsByTreeTotal.items;
                        currentToken = result.data.itemsByTreeTotal.nextToken;
                    }
                    break;
                case 'fruitVegTotal':
                    if (areaCode) {
                        result = await API.graphql(graphqlOperation(itemsByAreaCodeAndFruitVegTotal, variables));
                        items = result.data.itemsByAreaCodeAndFruitVegTotal.items;
                        currentToken = result.data.itemsByAreaCodeAndFruitVegTotal.nextToken;
                    } else {
                        result = await API.graphql(graphqlOperation(itemsByFruitVegTotal, variables));
                        items = result.data.itemsByFruitVegTotal.items;
                        currentToken = result.data.itemsByFruitVegTotal.nextToken;
                    }
                    break;
                case 'plantTotal':
                    if (areaCode) {
                        result = await API.graphql(graphqlOperation(itemsByAreaCodeAndPlantTotal, variables));
                        items = result.data.itemsByAreaCodeAndPlantTotal.items;
                        currentToken = result.data.itemsByAreaCodeAndPlantTotal.nextToken;
                    } else {
                        result = await API.graphql(graphqlOperation(itemsByPlantTotal, variables));
                        items = result.data.itemsByPlantTotal.items;
                        currentToken = result.data.itemsByPlantTotal.nextToken;
                    }
                    break;
                case 'flowerTotal':
                    if (areaCode) {
                        result = await API.graphql(graphqlOperation(itemsByAreaCodeAndFlowerTotal, variables));
                        items = result.data.itemsByAreaCodeAndFlowerTotal.items;
                        currentToken = result.data.itemsByAreaCodeAndFlowerTotal.nextToken;
                    } else {
                        result = await API.graphql(graphqlOperation(itemsByFlowerTotal, variables));
                        items = result.data.itemsByFlowerTotal.items;
                        currentToken = result.data.itemsByFlowerTotal.nextToken;
                    }
                    break;
                default:
                    if (variables.filter && variables.filter.and) {
                        const created = variables.filter.and.find(a => a.createdAt);
                        if (created) {
                            variables = {
                                ...variables,
                                ...created,
                                filter: null
                            };
                        }
                    }
                    if (areaCode) {
                        result = await API.graphql(graphqlOperation(itemsByAreaCodeAndCreatedAt, variables));
                        items = result.data.itemsByAreaCodeAndCreatedAt.items;
                        currentToken = result.data.itemsByAreaCodeAndCreatedAt.nextToken;
                    } else {
                        result = await API.graphql(graphqlOperation(createdAt, variables));
                        items = result.data.createdAt.items;
                        currentToken = result.data.createdAt.nextToken;
                    }

                    break;
            }

            setCurrentToken(currentToken);
            dispatch(setPlants(items));
            setIsLoading(false);
        } catch (err) { console.log('error fetching data: ', err); }

    }, [rowsPerPage, combinedFilters, sortDirection, areaCode, dispatch]);

    // Fetch plants on load
    useEffect(() => {
        fetchPlantPostData();
    }, [fetchPlantPostData]);

    // Filters have changed, set the current page to 0
    useEffect(() => {
        setCurrentPage(0);
    }, [combinedFilters]);

    useEffect(() => {
        // -1 count means we have an unknown total number of rows - which is unfortunately the case with AppSync GraphQL currently
        var rowCount = -1;

        // we don't have any more pages to show
        if (currentPage === 0 && plants.length < rowsPerPage)
            rowCount = plants.length;

        // we must be at the end of the result set now, so we can reliably provide the count
        if (currentPage > 0 && plants.length < rowsPerPage)
            rowCount = ((currentPage * rowsPerPage) + plants.length);

        setRowCount(rowCount);
    }, [currentPage, plants, combinedFilters, rowsPerPage]);

    async function addPlantPost(plantPost) {

        try {
            console.log(plantPost);
            const created = await API.graphql(graphqlOperation(createPlant, { input: plantPost }));
            console.log('created', created);
            // fetchAggregatedData();
            fetchPlantPostData();
            setCurrentPage(0);
        } catch (err) {
            fetchPlantPostData();
            setCurrentPage(0);
            console.log('error creating plant post:', err);
        }
    }

    async function deletePlantPost(plantPost) {
        try {
            console.log(plantPost);
            const toDelete = {
                id: plantPost.id,
            };
            console.log('toDelete', toDelete);
            await API.graphql({ query: deletePlant, variables: { input: toDelete } });
            // fetchAggregatedData();
            fetchPlantPostData();
            setCurrentPage(0);
        } catch (err) {
            console.log('error deleting post', err);
        }
    }

    const changePage = useCallback(async (event, page) => {
        if (page === 0) {
            // return to the index page
            await fetchPlantPostData();
            setPreviousTokens([]);
        }
        else if (page > currentPage) {
            // store our current page, before we fetch the next one
            // fetch the next page
            await fetchPlantPostData(currentToken);
        }
        else {
            // fetch the previous page
            await fetchPlantPostData(previousTokens.pop());
            setPreviousTokens([...previousTokens]);
        }
        setCurrentPage(page);

    }, [currentPage, currentToken, fetchPlantPostData, previousTokens]);

    function updateRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    }

    return (
        <div className="Manager__LoggedIn">
            <Button
                className="Manager__Actions"
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => setShowAddSubmissionModal(true)}
            >
                Add submission
            </Button>

            <AddSubmissionModal
                showAddSubmissionModal={showAddSubmissionModal}
                setShowAddSubmissionModal={setShowAddSubmissionModal}
                addPlantPost={addPlantPost}
            />
            <Totals />
            <FilterController />
            <Table plants={plants} deletePlantPost={deletePlantPost} loading={isLoading} />
            <TablePagination
                component="div"
                size="large"
                page={currentPage}
                onChangePage={changePage}
                count={rowCount}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={updateRowsPerPage}
                SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                }}
            />

        </div>
    );
};

export default Manager;