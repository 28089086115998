import { createSelector } from 'reselect';

const getAreaCode = (state) => state.filter.areaCode;

export const selectAreaCode = createSelector(getAreaCode, (areaCode) => areaCode);

const getCombinedFilter = (state) => state.filter.combinedFilters;

export const selectCombinedFilter = createSelector(getCombinedFilter, (combinedFilters) => combinedFilters);

const getSortDirection = (state) => state.filter.sortDirection;

export const selectSortDirection = createSelector(getSortDirection, (sortDirection) => sortDirection);