import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import postcodeData from '../data/postcodeArea';

const AddSubmissionModal = ({
    setShowAddSubmissionModal,
    showAddSubmissionModal,
    addPlantPost,
}) => {
    const initialFormState = {
        areaCode: postcodeData[0].Postcode,
        areaDescriptiveName: postcodeData[0].Name,
        plantTotal: 0,
        treeTotal: 0,
        flowerTotal: 0,
        fruitVegTotal: 0,
        gardenTotal: 0,
        riversAndCoastalTotal: 0,
        type: 'plant'
    };
    const [formState, setFormState] = useState({ ...initialFormState });
    const [postcodeInputValue, setPostcodeInputValue] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        console.log('e: ', e);
        console.log('formState:', formState);

        if (formState.plantTotal > 0 || formState.treeTotal > 0 || formState.flowerTotal > 0 || formState.fruitVegTotal > 0 || formState.gardenTotal > 0 || formState.riversAndCoastalTotal > 0) {
            addPlantPost(formState);
        }

        setFormState({ ...initialFormState });
        setShowAddSubmissionModal(false);
    };

    const handleChange = input => {
        const { id, value, validity } = input.target;
        const currState = formState;

        if (!validity.valid) return;

        if (id === "treeTotal" || id === "flowerTotal" || id === "plantTotal" || id === "fruitVegTotal" || id === "gardenTotal" || id === "riversAndCoastalTotal") {
            currState[id] = Number(value);
        }
        // else {
        //     currState[id] = value;
        // }
        setFormState({ ...currState });
    };

    const handlePostcodeChange = value => {
        console.log(value);
        const currState = formState;

        currState['areaCode'] = value ? value.Postcode : null;
        currState['areaDescriptiveName'] = value ? value.Name : null;

        setFormState({ ...currState });
        console.log({ ...currState });
    };

    // useEffect(() => {
    //     console.log('formState: ', formState);
    // }, [formState]);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.Postcode,
    });

    return (
        <Modal className="Manager__AddSubmission" open={showAddSubmissionModal}>
            <div className="Manager__AddSubmission-modal">
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => setShowAddSubmissionModal(false)}
                >
                    Close
                </Button>

                <h2>Plant map user submission</h2>

                <form className="Manager__Form" onSubmit={e => handleSubmit(e)}>
                    <TextField
                        id="riversAndCoastalTotal"
                        variant="outlined"
                        label="Rivers & Coastal"
                        type="number"
                        autoFocus
                        InputProps={{ inputProps: { min: 0, } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChange(e)}
                    />
                    <TextField
                        id="treeTotal"
                        variant="outlined"
                        label="Trees"
                        type="number"
                        autoFocus
                        InputProps={{ inputProps: { min: 0, } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChange(e)}
                    />
                    <TextField
                        id="fruitVegTotal"
                        variant="outlined"
                        label="Fruits & Veg"
                        type="number"
                        InputProps={{ inputProps: { min: 0, } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChange(e)}
                    />
                    <TextField
                        id="plantTotal"
                        variant="outlined"
                        label="Plants"
                        type="number"
                        InputProps={{ inputProps: { min: 0, } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChange(e)}
                    />
                    <TextField
                        id="flowerTotal"
                        variant="outlined"
                        label="Flowers"
                        type="number"
                        InputProps={{ inputProps: { min: 0, } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChange(e)}
                    />
                    <TextField
                        id="gardenTotal"
                        variant="outlined"
                        label="Gardens"
                        type="number"
                        InputProps={{ inputProps: { min: 0, } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChange(e)}
                    />
                    <Autocomplete
                        inputValue={postcodeInputValue}
                        onChange={(event, newValue) => {
                            handlePostcodeChange(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setPostcodeInputValue(newInputValue);
                        }}
                        id="areaCode"
                        options={postcodeData}
                        getOptionLabel={(option) => option.Postcode ? `${option.Postcode} (${option.Name})` : ""}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Postcode Area" variant="outlined" />}
                        filterOptions={filterOptions}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </Modal>
    );
};

export default AddSubmissionModal;
