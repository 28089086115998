import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { setAggregated } from '../store/plant/actions';
import { Container, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { selectTotals } from './../store/plant/selectors';


const listAggregatedPlants =
    `query listAggregatedPlants {
        listAggregatedPlants(limit: 242) {
            items {
            areaCode
            flowerTotal
            fruitVegTotal
            plantTotal
            treeTotal
            gardenTotal
            riversAndCoastalTotal
            total
            }
        }
    }`;

const Totals = () => {
    const dispatch = useDispatch();
    const totals = useSelector(selectTotals);
    const formatValue = (value) => parseInt(value).toLocaleString();
    useEffect(() => {
        async function fetchTotals() {
            try {
                const aggregatedData = await API.graphql({ query: listAggregatedPlants });
                dispatch(setAggregated(aggregatedData.data.listAggregatedPlants.items));
            } catch (error) {

            }
        }
        fetchTotals();
    }, [dispatch]);
    return (

        <div style={{ margin: -3, paddingTop: 12, paddingBottom: 12 }}>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                TOTAL
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.total)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                TREES
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.treeTotal)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                FRUITS & VEG
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.fruitVegTotal)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                PLANTS
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.plantTotal)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                FLOWERS
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.flowerTotal)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                GARDENS
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.gardenTotal)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                RIVERS & COASTAL
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {formatValue(totals.riversAndCoastalTotal)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>

    );
};

export default Totals;