/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishAggregatedPlant = /* GraphQL */ `
  mutation PublishAggregatedPlant($input: publishAggregatedInput!) {
    publishAggregatedPlant(input: $input) {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
    }
  }
`;
export const createPlant = /* GraphQL */ `
  mutation CreatePlant(
    $input: CreatePlantInput!
    $condition: ModelPlantConditionInput
  ) {
    createPlant(input: $input, condition: $condition) {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const updatePlant = /* GraphQL */ `
  mutation UpdatePlant(
    $input: UpdatePlantInput!
    $condition: ModelPlantConditionInput
  ) {
    updatePlant(input: $input, condition: $condition) {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const deletePlant = /* GraphQL */ `
  mutation DeletePlant(
    $input: DeletePlantInput!
    $condition: ModelPlantConditionInput
  ) {
    deletePlant(input: $input, condition: $condition) {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const createAggregatedPlant = /* GraphQL */ `
  mutation CreateAggregatedPlant(
    $input: CreateAggregatedPlantInput!
    $condition: ModelaggregatedPlantConditionInput
  ) {
    createAggregatedPlant(input: $input, condition: $condition) {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
export const updateAggregatedPlant = /* GraphQL */ `
  mutation UpdateAggregatedPlant(
    $input: UpdateAggregatedPlantInput!
    $condition: ModelaggregatedPlantConditionInput
  ) {
    updateAggregatedPlant(input: $input, condition: $condition) {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggregatedPlant = /* GraphQL */ `
  mutation DeleteAggregatedPlant(
    $input: DeleteAggregatedPlantInput!
    $condition: ModelaggregatedPlantConditionInput
  ) {
    deleteAggregatedPlant(input: $input, condition: $condition) {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
