import React, { useState, useEffect } from "react";
import { FormSection } from './UI/FormSection';
import { SectionHeader } from './UI/SectionHeader';
import { SectionBody } from './UI/SectionBody';
import { SectionFooter } from './UI/SectionFooter';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from '@material-ui/lab';
import { I18n, isEmpty, ConsoleLogger as Logger } from '@aws-amplify/core';
import { totpQrcode } from '@aws-amplify/ui';
import QRCode from 'qrcode.react';
const validAuthStates = ["TOTPSetup"];
const logger = new Logger('TOTPSetupComp');

const CustomTOTPSetup = ({ authState, onStateChange, authData, hide }) => {
    const [error, setError] = useState(null);
    const [code, setCode] = useState(null);
    const [setupMessage, setSetupMessage] = useState(null);
    const { register, handleSubmit, formState } = useForm({
        mode: "onChange"
    });
    const { isDirty, isValid } = formState;
    useEffect(() => {
        const user = authData;
        async function setup() {
            const platform = encodeURI(I18n.get('AWSCognito'));
            const issuer = encodeURI(I18n.get('PlantBritain'));
            if (!Auth || typeof Auth.setupTOTP !== 'function') {
                throw new Error(
                    'No Auth module found, please ensure @aws-amplify/auth is imported'
                );
            }
            try {
                const data = await Auth.setupTOTP(user);
                const codeUrl =
                    'otpauth://totp/' +
                    platform +
                    ':' +
                    user.username +
                    '?secret=' +
                    data +
                    '&issuer=' +
                    issuer;

                setCode(codeUrl);
            } catch (err) {
                console.log('totp setup failed', err);
                logger.debug('totp setup failed', err);
            }

        }

        if (validAuthStates.includes(authState)) {
            setup();
        }

    }, [authData, authState]);

    const checkContact = async (user) => {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        try {
            const data = await Auth.verifiedContact(user);
            if (!isEmpty(data.verified)) {
                onStateChange('signedIn', user);
            } else {
                const newUser = Object.assign(user, data);
                onStateChange('verifyContact', newUser);
            }
            window.location.reload();
        } catch (error) {
            console.log('error', error);
        }
    };

    const triggerTOTPEvent = (event, data, user) => {
        logger.debug('on totp event', event, data);
        if (event === 'Setup TOTP') {
            if (data === 'SUCCESS') {
                checkContact(user);
            }
        }
    };


    const verifyTotpToken = async ({ totpCode }) => {
        if (
            !Auth ||
            typeof Auth.verifyTotpToken !== 'function' ||
            typeof Auth.setPreferredMFA !== 'function'
        ) {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        const user = authData;
        try {
            await Auth.verifyTotpToken(user, totpCode);
            try {
                await Auth.setPreferredMFA(user, 'TOTP');
                setSetupMessage('Setup successfully!');
                logger.debug('set up totp success!');
                triggerTOTPEvent('Setup TOTP', 'SUCCESS', user);
            } catch (err) {
                setError('Setup failed!');
            }
        } catch (err) {
            console.log('setup error', err);
            setError('Setup failed!');
            logger.error(err);
        }

    };

    const isSubmitDisabled = () => {
        let submitDisabled = true;
        if (isDirty && isValid) {
            submitDisabled = false;
        } else submitDisabled = true;
        return submitDisabled;
    };

    if (!validAuthStates.includes(authState)) {
        return null;
    }


    const showSecretCode = (code) => {
        if (!code) {
            return null;
        }
        return (
            <div>
                <div className={totpQrcode}>
                    <QRCode value={code} />
                </div>
                <TextField
                    id="totpCode"
                    variant="outlined"
                    label="Code"
                    name="totpCode"
                    type="text"
                    fullWidth
                    autoFocus
                    required
                    inputRef={register({ required: true })}
                />
            </div>
        );
    };

    return (
        <FormSection>
            <SectionHeader>
                Scan then enter verification code
                </SectionHeader>
            <form onSubmit={handleSubmit(verifyTotpToken)} style={{ width: '100%' }}>
                <SectionBody>
                    {error && <Alert severity="error" style={{ marginBottom: '20px' }}>
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}
                    {setupMessage && <Alert severity="success" style={{ marginBottom: '20px' }}>
                        <AlertTitle>Success</AlertTitle>
                        {setupMessage}
                    </Alert>}
                    {showSecretCode(code)}
                </SectionBody>
                <SectionFooter>
                    <Button
                        type="submit"
                        // disabled={isSubmitDisabled()}
                        fullWidth
                        variant="contained"
                        color="primary"
                    >Verify Security Token</Button>
                </SectionFooter>
            </form>
        </FormSection>
    );

};

export default CustomTOTPSetup;
