import { SET_FILTER, SET_SORT } from './constants';

export function setTableFilter(toFilter, filter) {
    return {
        type: SET_FILTER,
        toFilter,
        filter,
    };
}

export function setTableSort(payload) {
    return {
        type: SET_SORT,
        payload
    };
}