import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import { format } from 'date-fns';
import DeleteModal from './DeleteModal';
import postCodes from '../data/postcodeArea';
import { setTableSort } from '../store/filter/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectSortDirection } from '../store/filter/selectors';

const Table = ({ plants, deletePlantPost, loading }) => {
    const dispatch = useDispatch();
    const sortDirection = useSelector(selectSortDirection);
    const [itemToDelete, setItemToDelete] = useState(false);

    const plantsRows = plants && plants.map(plant => {
        const areaCodeName = postCodes.find(p => p.Postcode === plant.areaCode);
        return ({
            ...plant,
            date: format(new Date(plant.createdAt), 'd/M/y H:mm'),
            areaDescriptiveName: `${plant.areaCode} (${areaCodeName ? areaCodeName.Name : ''})`,
            delete: ''
        });
    });

    const columns = [
        { field: 'date', headerName: 'Date', resizable: false, width: 200, align: 'left' },
        { field: 'treeTotal', headerName: 'Trees', numeric: true, resizable: false, width: 120, align: 'left' },
        { field: 'fruitVegTotal', headerName: 'Fruits & Veg', numeric: true, resizable: false, width: 120, align: 'left' },
        { field: 'plantTotal', headerName: 'Plants', numeric: true, resizable: false, width: 120, align: 'left' },
        { field: 'flowerTotal', headerName: 'Flowers', numeric: true, resizable: false, width: 120, align: 'left' },
        { field: 'gardenTotal', headerName: 'Gardens', numeric: true, resizable: false, width: 120, align: 'left' },
        { field: 'riversAndCoastalTotal', headerName: 'Rivers & Coastal', numeric: true, resizable: false, width: 120, align: 'left' },
        { field: 'areaDescriptiveName', headerName: 'Postcode Area', resizable: false, width: 200, align: 'left', sortable: false },
        {
            field: 'delete', headerName: 'Delete', resizable: false, width: 120, align: 'right', sortable: false,
            renderCell: params => (
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => toggleDeleteModal(params)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    const toggleDeleteModal = params => {
        if (params) {
            // const { id } = params.data;
            const entry = params;
            delete entry.data.delete;
            delete entry.data.date;

            setItemToDelete(entry.data);
        } else {
            setItemToDelete(false);
        }
    };

    const handleSortModelChange = (params) => {
        if (JSON.stringify(params.sortModel) !== JSON.stringify([sortDirection])) {
            dispatch(setTableSort(params.sortModel[0]));
        }
    };


    return (
        <div className="Manager__Table">
            {itemToDelete
                ? <DeleteModal
                    itemToDelete={itemToDelete}
                    toggleDeleteModal={toggleDeleteModal}
                    deletePlantPost={deletePlantPost}
                />
                : ''
            }
            {plantsRows
                ? <DataGrid
                    style={{ width: '100%' }}
                    rows={plantsRows}
                    columns={columns}
                    pageSize={plantsRows.length}
                    paginationMode="server"
                    sortingOrder={['desc', 'asc']}
                    sortingMode="server"
                    sortModel={[sortDirection]}
                    onSortModelChange={handleSortModelChange}
                    hideFooterPagination
                    loading={loading}
                    disableExtendRowFullWidth={false}
                    disableSelectionOnClick={true}
                />
                : ''
            }
        </div>
    );
};

export default Table;
