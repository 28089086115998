import React from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

const DeleteModal = ({ itemToDelete, toggleDeleteModal, deletePlantPost }) => {
    const deleteFromDatabase = () => {
        console.log('Delete entry: ', itemToDelete);
        deletePlantPost(itemToDelete);
        console.log('Then close modal...');
        toggleDeleteModal(false);
    };

    return (
        <Modal className="Manager__Delete" open>
            <div className="Manager__Delete-modal">
                <h3>Confirm Delete</h3>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={deleteFromDatabase}
                >
                    Confirm
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={() => toggleDeleteModal(false)}
                >
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteModal;
