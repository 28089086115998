import React, { useState } from "react";
import { FormSection } from './UI/FormSection';
import { SectionHeader } from './UI/SectionHeader';
import { SectionBody } from './UI/SectionBody';
import { SectionFooter } from './UI/SectionFooter';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from '@material-ui/lab';
import { I18n, isEmpty, ConsoleLogger as Logger } from '@aws-amplify/core';
const logger = new Logger('RequireNewPassword');
const validAuthStates = ["requireNewPassword"];

const CustomRequireNewPassword = ({ authState, onStateChange, authData }) => {
    const [error, setError] = useState(null);
    const { register, handleSubmit, formState } = useForm({
        mode: "onChange"
    });
    const { isDirty, isValid } = formState;

    const checkContact = async (user) => {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        try {
            const data = await Auth.verifiedContact(user);
            if (!isEmpty(data.verified)) {
                onStateChange('signedIn', user);
            } else {
                user = Object.assign(user, data);
                onStateChange('verifyContact', user);
            }
        } catch (error) {

        }
    };

    const errorMessage = (err) => {
        if (typeof err === 'string') {
            return err;
        }
        return err.message ? err.message : JSON.stringify(err);
    };


    const change = async (inputs) => {
        const user = authData;
        const { password } = inputs;
        const { requiredAttributes } = user.challengeParam;
        const attrs = objectWithProperties(inputs, requiredAttributes);
        try {
            if (!Auth || typeof Auth.completeNewPassword !== 'function') {
                throw new Error(
                    'No Auth module found, please ensure @aws-amplify/auth is imported'
                );
            }
            const updatedUser = await Auth.completeNewPassword(user, password, attrs);
            if (updatedUser.challengeName === 'SMS_MFA') {
                onStateChange('confirmSignIn', updatedUser);
            } else if (updatedUser.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', updatedUser.challengeParam);
                onStateChange('TOTPSetup', updatedUser);
            } else {
                checkContact(updatedUser);
            }
            logger.debug('complete new password', user);
        } catch (err) {
            setError(errorMessage(err));

        }
    };

    const isSubmitDisabled = () => {
        let submitDisabled = true;
        if (isDirty && isValid) {
            submitDisabled = false;
        } else submitDisabled = true;
        return submitDisabled;
    };

    if (!validAuthStates.includes(authState)) {
        return null;
    }

    const user = authData;
    const { requiredAttributes } = user.challengeParam;
    return (
        <FormSection>
            <SectionHeader>
                Change Password
                </SectionHeader>
            <form onSubmit={handleSubmit(change)} style={{ width: '100%' }}>
                <SectionBody>
                    {error && <Alert severity="error" style={{ marginBottom: '20px' }}>
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}

                    <TextField
                        id="password"
                        variant="outlined"
                        label="Password"
                        name="password"
                        type="password"
                        fullWidth
                        autoFocus
                        required
                        inputRef={register({ required: true })}
                    />

                    {requiredAttributes && requiredAttributes.map(attribute => (
                        <TextField
                            variant="outlined"
                            label={I18n.get(convertToPlaceholder(attribute))}
                            name={attribute}
                            type="text"
                            fullWidth
                            inputRef={register()}
                        />
                    ))}
                </SectionBody>
                <SectionFooter>
                    <Button
                        type="submit"
                        disabled={isSubmitDisabled()}
                        fullWidth
                        variant="contained"
                        color="primary"
                    >Confirm</Button>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => onStateChange("signIn", {})}
                                type="button"
                            >
                                Back to Sign In</Link>

                        </Grid>
                    </Grid>
                </SectionFooter>
            </form>
        </FormSection>
    );

};

function convertToPlaceholder(str) {
    return str
        .split('_')
        .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
        .join(' ');
}

function objectWithProperties(obj, keys) {
    const target = {};
    for (const key in obj) {
        if (keys.indexOf(key) === -1) {
            continue;
        }
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        target[key] = obj[key];
    }
    return target;
}

export default CustomRequireNewPassword;
