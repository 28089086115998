import { SET_PLANTS, SET_AGGREGATED } from './constants';

export function setPlants(payload) {
    return {
        type: SET_PLANTS,
        payload
    };
}

export function setAggregated(payload) {
    return {
        type: SET_AGGREGATED,
        payload
    };
}