import React from 'react';
import InternalApp from './InternalApp';
import CustomSignIn from './components/Auth/CustomSignIn';
import CustomForgot from './components/Auth/CustomForgot';
import CustomConfirmSignIn from './components/Auth/CustomConfirmSignIn';
import CustomRequireNewPassword from './components/Auth/CustomRequireNewPassword';
import CustomTOTPSetup from './components/Auth/CustomTOTPSetup';

const AuthWrapper = (props) => {
    return (
        <>
            <CustomSignIn {...props}
            />
            <CustomForgot  {...props} />
            <CustomConfirmSignIn {...props} />
            <CustomRequireNewPassword {...props} />
            <CustomTOTPSetup {...props} />
            <InternalApp  {...props} />
        </>);
};

export default AuthWrapper;