import React, { useState } from "react";
import { FormSection } from './UI/FormSection';
import { SectionHeader } from './UI/SectionHeader';
import { SectionBody } from './UI/SectionBody';
import { SectionFooter } from './UI/SectionFooter';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from '@material-ui/lab';
import { isEmpty } from '@aws-amplify/core';
const validAuthStates = ["confirmSignIn"];

const CustomConfirmSignIn = ({ authState, onStateChange, authData }) => {
    const [error, setError] = useState(null);
    const { register, handleSubmit, formState } = useForm({
        mode: "onChange"
    });
    const { isDirty, isValid } = formState;

    const checkContact = async (user) => {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        try {
            const data = await Auth.verifiedContact(user);
            if (!isEmpty(data.verified)) {
                onStateChange('signedIn', user);
            } else {
                user = Object.assign(user, data);
                onStateChange('verifyContact', user);
            }
        } catch (error) {

        }
    };

    const errorMessage = (err) => {
        if (typeof err === 'string') {
            return err;
        }
        return err.message ? err.message : JSON.stringify(err);
    };


    const confirm = async ({ code }) => {
        try {
            const mfaType =
                authData.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : null;
            if (!Auth || typeof Auth.confirmSignIn !== 'function') {
                throw new Error(
                    'No Auth module found, please ensure @aws-amplify/auth is imported'
                );
            }
            const user = await Auth.confirmSignIn(authData, code, mfaType);
            checkContact(user);
        } catch (err) {
            setError(errorMessage(err));
        }

    };

    const isSubmitDisabled = () => {
        let submitDisabled = true;
        if (isDirty && isValid) {
            submitDisabled = false;
        } else submitDisabled = true;
        return submitDisabled;
    };

    if (!validAuthStates.includes(authState)) {
        return null;
    }

    return (
        <FormSection>
            <SectionHeader>
                Confirm Authenticator App Code
                </SectionHeader>
            <form onSubmit={handleSubmit(confirm)} style={{ width: '100%' }}>
                <SectionBody>
                    {error && <Alert severity="error" style={{ marginBottom: '20px' }}>
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}

                    <TextField
                        id="code"
                        variant="outlined"
                        label="Code"
                        name="code"
                        type="text"
                        fullWidth
                        autoFocus
                        required
                        inputRef={register({ required: true })}
                    />
                </SectionBody>
                <SectionFooter>
                    <Button
                        type="submit"
                        disabled={isSubmitDisabled()}
                        fullWidth
                        variant="contained"
                        color="primary"
                    >Confirm</Button>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => onStateChange("signIn", {})}
                                type="button"
                            >
                                Back to Sign In</Link>

                        </Grid>
                    </Grid>
                </SectionFooter>
            </form>
        </FormSection>
    );

};

export default CustomConfirmSignIn;
