import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) =>
    createStyles({
        box: {
            padding: `${_theme.spacing(3)}px 0`,
        },
    })
);

export const SectionBody = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.box}>{children}</Box>;
};