import { SET_PLANTS, SET_AGGREGATED } from "./constants";

export const initialState = {
    plants: [],
    aggregated: [],
    totals: {
        flowerTotal: 0,
        fruitVegTotal: 0,
        plantTotal: 0,
        treeTotal: 0,
        gardenTotal: 0,
        riversAndCoastalTotal: 0,
        total: 0
    }
};

function count(array, key) {
    return array.reduce((r, a) => r + a[key], 0);
}

const plantReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLANTS:
            return {
                ...state,
                plants: action.payload
            };
        case SET_AGGREGATED:
            const flowerTotal = count(action.payload, 'flowerTotal');
            const fruitVegTotal = count(action.payload, 'fruitVegTotal');
            const plantTotal = count(action.payload, 'plantTotal');
            const treeTotal = count(action.payload, 'treeTotal');
            const gardenTotal = count(action.payload, 'gardenTotal');
            const riversAndCoastalTotal = count(action.payload, 'riversAndCoastalTotal');
            const total = count(action.payload, 'total');
            return {
                ...state,
                aggregated: action.payload,
                totals: {
                    flowerTotal,
                    fruitVegTotal,
                    plantTotal,
                    treeTotal,
                    gardenTotal,
                    riversAndCoastalTotal,
                    total
                }
            };
        default:
            return state;
    }
};

export default plantReducer;
